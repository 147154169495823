export default {
  Homepage: "Homepage",
  Login: "Login",
  Cabinet: "Cabinet",
  Logout: "Logout",
  AboutAssociation: "About Association",
  Leadership: "Leadership",
  Documents: "Documents",
  Partners: "Partners",
  Contacts: "Contacts",
  Deposition: "Deposition",
  RightsHolders: "Rights Holders",
  Users: "Users",
  Registers: "Registers",
  News: "News",
  SubBroadcasting: "Broadcasting",
  SubTheaters: "Theaters",
  SubCinemas: "Cinemas",
  SubBarsAndRestaurants: "Bars and restaurants",
  SubTrading: "Trading companies",
  SubMusicVenues: "Music and entertainment venues",
  SubHotels: "Hotels",
  SubPassengerTransportation: "Passenger transportation companies",
  SubPublicEvents: "Public events",
  SubPaymentsByInstall: "Payments by installments",
  SubServiceSector: "Service sector enterprises",
  WelcomeSite: "Welcome to the Authors Association",
  SiteTitle: "Union of representatives of the film industry of Uzbekistan",
  SiteDescription:
    "The main objective is to manage the copyright of film industry representatives on a collective basis, support film industry representatives and ensure the creation of favorable conditions for the production of high-quality films that meet national interests",
  OurPartner: "Our partners",
  GeneralInformation: "general information",
  AuthorsUnion: "Union of representatives of the film industry of uzbekistan",
  AuthorsUnionText1:
    "The non-governmental non-profit organization `Union of Representatives of the Film Industry of Uzbekistan` was established in accordance with the Decree of the President of the Republic of Uzbekistan No. UP-6202 dated April 7, 2021 `On measures to raise the cinematic arts and film industry to a qualitatively new level and further improve the system of state support for the industry`.",
  AuthorsUnionText2:
    "The main goal of the Union is to manage the copyright of film industry representatives on a collective basis, support film industry representatives and ensure the creation of favorable conditions for the production of high-quality films that meet national interests.",
  AuthorsUnionText3:
    "The Union also carries out activities to protect the rights of authors of works and holders of rights to intellectual property objects and the storage (deposit) of objects of their copyright.",
  AuthorsUnionText4:
    "In its activities, the Union cooperates with the Ministry of Culture of the Republic of Uzbekistan, the Ministry of Justice, the Cinematography Agency and other ministries and departments, state and non-state film studios, authors and their associations.",
  AuthorsUnionText5:
    "Individuals and legal entities who recognize and respect its Charter may become members of the Union. The Union ensures equal protection of the rights and interests of all its members.",
  More: "More",
  YearsOfExperience: "Years of experience",
  Employees: "Employees",
  Management: "Partners",
  PhotoMaterials: "Photo materials",
  VideoMaterials: "Video materials",
  All: "All",
  AboutUs: "About Us",
  Media: "Media",
  Teleradio: "Teleradio companies",
  Cinemas: "Cinemas",
  CafeRestaurants: "Cafes and restaurants",
  Shopping: "Shopping complexes",
  Hotels: "Hotels",
  DataEntry: "Data entry",
  FileUpload: "Upload file",
  DataVerification: "Data verification",
  Payment: "Payment",
  DepositionProcess: "Deposition process",
  ProcessText:
    "You can deposit a file of any work up to 5 GB for 2 to 10 years.",
  HandwritingName: "The name of the work",
  AddCoAuthor: "Add Co-author",
  FirstName: "First name",
  LastName: "Last name",
  MiddleName: "Middle name",
  Next: "Next",
  Prev: "Prev",
  DropFiles: "Drop the files here or",
  SelectFromDevice: "Select the file from the device",
  Applicant: "Applicant",
  Address: "Address",
  PassportSerialNumber: "Passport serial number",
  DownloadedFile: "Downloaded file",
  ServicePrice: "Service price",
  Save: "Save",
  Sending: "Sending",
  PaymentText:
    "To make a payment on the site, choose one of the proposed payment methods: Payme, Click. Then follow the instructions on the payment system's website",
  PaymentText2:
    "After making the payment, check the status of your application and wait for approval.",
  ProceedToPayment: "Proceed to payment",
  Applications: "Applications",
  PersonalCabinet: "Personal cabinet",
  PersonalInformation: "Personal information",
  AddressInformation: "Address information",
  PassportInformation: "Passport information",
  DateOfBirth: "Date of birth",
  PhoneNumber: "Phone number",
  WhenGiven: "When given",
  GivenByWho: "Given by whom",
  Author: "Author",
  RegistrationDate: "Registration date",
  Status: "Status",
  Status1: "Payment is pending",
  Status2: "Canceled",
  Status3: "Paid",
  PieceType: "Type of work",
  OwnerRights: "Owner of rights",
  PieceDate: "The date the work was",
  OwnAuthor: "I am the author",
  OrgAddress: "Location address",
  Director: "Executive's F.I.O",
  notEntered: "not selected!",
  notSelected: "not selected!",
  errorEntered: "entered incorrectly!",
  legalPayInfo:
    "Payments for legal entities are made by transferring money from their bank account on the basis of the contract",
  termsNotClick: "Terms of use",
  termsAccept: "I agree to the terms of use",
  termsPage: "Please read",
  certificate: "Certificate",
  docTitle: "Documents on the activities of the Union",
  docButton1: "test",
  docButton2: "test",
  docButton3: "test",
  docButton4: "Regulatory documents",
};