export default {
  Homepage: "Bosh sahifa",
  Login: "Kirish",
  Cabinet: "Kabinet",
  Logout: "Chiqish",
  AboutAssociation: "Uyushma haqida",
  Leadership: "Rahbariyat",
  Documents: "Hujjatlar",
  Partners: "Hamkorlar",
  Contacts: "Kontaktlar",
  Deposition: "Deponentlash",
  RightsHolders: "Huquq egalari",
  Users: "Foydalanuvchilar",
  Registers: "Reyestrlar",
  News: "Yangiliklar",
  SubBroadcasting: "Teleradiokompaniyalar",
  SubTheaters: "Teatrlar",
  SubCinemas: "Kinoteatrlar",
  SubBarsAndRestaurants: "Bar va restoranlar",
  SubTrading: "Savdo korxonalari",
  SubMusicVenues: "Musiqali va o‘yin muassasalari",
  SubHotels: "Mehmonxonalar",
  SubPassengerTransportation: "Yo‘lovchi tashish korxonalari",
  SubPublicEvents: "Ommamiy tadbirlar",
  SubPaymentsByInstall: "Qo‘chimcha to‘lovlar",
  SubServiceSector: "Xizmat ko'rsatish sohasi korxonalari",
  WelcomeSite: "Mualliflar uyushmasiga xush kelibsiz",
  SiteTitle: "O‘zbekiston kino sanoati vakillari uyushmasi",
  SiteDescription:
    "Kino san’ati va sanoati vakillarining mualliflik huquqlarini jamoaviy asosda boshqarish, kino sanoati vakillarini qo‘llab quvvatlash hamda milliy manfaatlarga mos keladigan sifatli filmlar ishlab chiqarish uchun qulay shart-sharoitlar yaratilishini ta’minlash Uyushmaning asosiy maqsadi hisoblanadi",
  OurPartner: "Bizning hamkorlar",
  GeneralInformation: "umumiy ma’lumot",
  AuthorsUnion: "O‘zbekiston kino sanoati vakillari uyushmasi",
  AuthorsUnionText1:
    "“O‘zbekiston kino sanoati vakillari uyushmasi” O‘zbekiston Respublikasi Prezidentining 2021-yil 7-apreldagi “Kino san’ati va sanoatini yangi bosqichga olib chiqish, sohani davlat tomonidan qo‘llab-quvvatlash tizimini yanada takomillashtirish to‘g‘risida”gi PF–6202-son Farmoniga muvofiq tashil etilgan nodavlat notijorat tashkilotidir.",
  AuthorsUnionText2:
    "Uyushmaning asosiy maqsadi – kino san’ati va sanoati vakillarining mualliflik huquqlarini jamoaviy asosda boshqarish, kino sanoati vakillarini qo‘llab quvvatlash hamda milliy manfaatlarga mos keladigan sifatli filmlar ishlab chiqarish uchun qulay shart-sharoitlar yaratilishini ta’minlash hisoblanadi.",
  AuthorsUnionText3:
    "Shuningdek, Uyushma asarlar mualliflari va ularga nisbatan huquq egalarining intellektual mulk ob’ektlariga bo‘lgan huquqlarini himoya qilish hamda ularining mualliflik huquqi ob’ektlarini saqlash (deponentlash) faoliyatini amalga oshiradi.",
  AuthorsUnionText4:
    "Uyushma o‘z faoliyatida O‘zbekiston Respublikasi Madaniyat vazirligi, Adliya vazirligi, Kinematografiya agentligi va boshqa vazirlik-idoralar, davlat va nodavlat kinostudiyalar hamda mualliflar va ularning birlashmalari bilan hamkorlik qiladi.",
  AuthorsUnionText5:
    "Uyushmaga uning Ustavini tan olgan va hurmat qilgan jismoniy va yuridik shaxslar a’zo bo‘lishlari mumkin. Uyushma barcha a’zolarining huquq va manfaatlarini teng ravishda himoya qilinishini ta’minlaydi.",
  More: "Batafsil",
  YearsOfExperience: "Yillik tajriba",
  Employees: "Hodimlar",
  Management: "Boshqaruv",
  PhotoMaterials: "Fotomateriallar",
  VideoMaterials: "Videomateriallar",
  All: "Barchasi",
  AboutUs: "Biz haqimizda",
  Media: "Media",
  Teleradio: "Teleradiokompaniyalar",
  Cinemas: "Kinoteatralar",
  CafeRestaurants: "Kafe va restoranlar",
  Shopping: "Savdo komplexlari",
  Hotels: "Mexmonxonalar",
  DataEntry: "Ma’lumotlarni kiritish",
  FileUpload: "Faylni yuklash",
  DataVerification: "Ma’lumotlarni tekshirish",
  Payment: "To‘lov qilish",
  DepositionProcess: "Deponentlash jarayoni",
  ProcessText:
    "Har qanday asarning 5 GB gacha bo'lgan faylini 2 dan 10 yilgacha deponentlashtirishingiz mumkin.",
  HandwritingName: "Asarning nomi",
  AddCoAuthor: "Hammuallif qo‘shish",
  FirstName: "Ismi",
  LastName: "Familiyasi",
  MiddleName: "Otasini ismi",
  Next: "Keyingi",
  Prev: "Oldingi",
  DropFiles: "Fayllarni shu yerga tashlang yoki",
  SelectFromDevice: "Qurilmadan faylni tanlang",
  Applicant: "Arizachi",
  Address: "Manzil",
  PassportSerialNumber: "Passport seriya raqami",
  DownloadedFile: "Yuklangan fayl",
  ServicePrice: "Xizmat narxi",
  Save: "Saqlash",
  Sending: "Yuborilmoqda",
  PaymentText:
    "Saytda to‘lovni amalga oshirish uchun taklif qilingan to‘lov usullaridan birini tanlang: Payme, Click. Keyin to‘lov tizimi web-saytidagi ko‘rsatmalarga amal qiling",
  PaymentText2:
    "To‘lovni amalga oshirgandan so‘ng arizangizning holatini tekshiring va tasdiqlanishini kuting.",
  ProceedToPayment: "To‘lovga o‘tish",
  Applications: "Arizalar",
  PersonalCabinet: "Shaxsiy kabinet",
  PersonalInformation: "Shaxsiy ma’lumotlar",
  AddressInformation: "Manzil ma’lumotlari",
  PassportInformation: "Passport ma’lumotlari",
  DateOfBirth: "Tug‘ilgan sanasi",
  PhoneNumber: "Telefon raqami",
  WhenGiven: "Qachon berilgan",
  GivenByWho: "Kim tomonidan berilgan",
  Author: "Muallifi",
  RegistrationDate: "Ro‘yxatga olish sanasi	",
  Status: "Ariza holati",
  Status1: "To‘lov kutilmoqda",
  Status2: "Bekor qilindi",
  Status3: "To‘landi",
  PieceType: "Asarning turi",
  OwnerRights: "Huquq egasi",
  PieceDate: "Asar yaratilgan sana",
  OwnAuthor: "Muallif o‘zim",
  OrgAddress: "Manzili",
  Director: "Rahbarning F.I.O",
  notEntered: "kiritilmadi!",
  notSelected: "tanlanmadi!",
  errorEntered: "notoʻgʻri kiritildi!",
  legalPayInfo:
    "Yuridik shaxslar uchun to`lovlar shartnoma asosida ularning bank hisob-raqamidan pul ko`chirish yo`li bilan amalga oshiriladi",
  termsNotClick: "Foydalanish shartlari",
  termsAccept: "Deponentlash shartlariga roziman",
  termsPage: "Iltimos tanishing",
  certificate: "Sertifikat",
  docTitle: "Uyushma faoliyatiga oid hujjatlar",
  docButton1: "Uyushma guvohnomasi",
  docButton2: "Uyushma ustavi",
  docButton3: "Stavkalar haqida nizomi",
  docButton4: "Normativ-huquqiy hujjatlar",
};